<template>
  <div class="featured-videos-list" :class="[columnsCount(items.length), variant]">
    <a v-for="item in items" :key="item.objectID" href="" class="featured-videos-list__item" @click.prevent="handleClick(item)">
      <div class="featured-videos-list__item-image-wrap">
        <picture-basic v-if="item.cover_image" v-bind="getPictureBindings(item.cover_image, { contain: false })" />
        <div class="featured-videos-list__item-logo">
          <svg width="50" height="34" viewBox="0 0 50 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="34" rx="4" fill="#DC291E" />
            <path d="M34 17L18 26L18 8L34 17Z" fill="white" />
          </svg>
        </div>
      </div>
      <span class="featured-videos-list__item-title title is-4">
        {{ item.title }}
      </span>
      <span class="featured-videos-list__item-excerpt">
        {{ item.excerpt }}
      </span>
    </a>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount } from 'vue';
import { FEATURED_VIDEO_POPUP } from '@/constants/popups';
import type { LooseObject } from '~/types/generic';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import { useFeaturedVideoStore } from '~/stores/featured-video';
import { usePopupsStore } from '~/stores/popups';

const { setCurrentVideo } = useFeaturedVideoStore();
const { openPopup, closePopup } = usePopupsStore();

const props = defineProps({
  items: {
    type: Array as () => Array<LooseObject>,
    required: true,
  },
  hasMenu: {
    type: Boolean,
    default: true,
  },
  variant: {
    validator(value: string) {
      return ['small', 'medium', 'large'].includes(value);
    },
    default: 'medium',
  },
  onClick: {
    type: Function,
    default: () => {},
  },
});

const columnsCount = (length) => {
  if (length > 2 && !props.hasMenu) {
    return 'is-3';
  }

  if (length === 2 || (length > 2 && props.hasMenu)) {
    return 'is-2';
  }

  return 'is-1';
};

const handleClick = (video) => {
  props.onClick('click', video, 'Hit Clicked!');
  setCurrentVideo(video);
  openPopup(FEATURED_VIDEO_POPUP);
};

onBeforeUnmount(() => {
  closePopup();
});
</script>
