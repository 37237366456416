<template>
  <div class="categories-menu">
    <div class="categories-menu__top">
      <p class="categories-menu__title title is-4" :class="{ 'is-opened': showMenu }" @click.prevent="toggle">
        <span class="categories-menu__toggler">
          <pt-categories-menu-icon />
        </span>
        <span>Refine by category</span>
      </p>

      <template v-if="!!$slots.reset">
        <slot name="reset" />
      </template>
    </div>

    <transition name="fadeHeight">
      <div v-show="showMenu || !isMobile" class="categories-menu__list">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { useWindowSize } from '@vueuse/core';
import PtCategoriesMenuIcon from '@/components/global/icons/PtCategoriesMenuIcon.vue';

const showMenu = ref(false);
const isMobile = ref(false);

const { width } = useWindowSize();

const toggle = () => {
  showMenu.value = !showMenu.value;
};

const onResize = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => onResize());
watch(width, () => onResize());
</script>
