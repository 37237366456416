import type { Nullable, NullableOrUndefinable } from '~/types/generic';

export function toCamelCase(str: string) {
  return (' ' + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (_match, chr) {
    return chr.toUpperCase();
  });
}

export function firstLetterToLowerCase(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function firstLetterToUpperCase(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function truncate(str: string, n: number, useWordBoundary = false) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1); // the original check
  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + '&hellip;';
}

export function sanitize(str: string) {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || '';
}

export function getExtension(str: NullableOrUndefinable<string>): Nullable<string> {
  const re = /(?:\.([^.]+))?$/;

  return re.exec(str)[1] || null;
}
