<template>
  <div class="featured-files-list">
    <div v-for="item in items" :key="item.objectID" class="featured-files-list__item">
      <a :href="item.url" class="featured-files-list__link" target="_blank" @click="() => onClick('click', item, 'Hit Clicked!')">
        <div class="featured-files-list__link-inner">
          <pt-file-icon :type="getExtFile(item.url)" class="featured-files-list__file-icon" />
          <div class="featured-files-list__link-content">
            <h3 class="title is-4">{{ item.alt || item.title }}</h3>
            <p v-if="item.description" class="description">
              {{ item.description }}
            </p>
          </div>
        </div>

        <span class="featured-files-list__download-icon">
          <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_218_7934)">
              <path
                d="M22.546 17.4375C22.2246 17.4375 21.9163 17.5652 21.689 17.7925C21.4617 18.0198 21.334 18.3281 21.334 18.6495V20.5045C21.3335 20.9209 21.1678 21.3201 20.8732 21.6144C20.5787 21.9088 20.1794 22.0742 19.763 22.0745H3.995C3.5786 22.0742 3.17931 21.9088 2.88478 21.6144C2.59025 21.3201 2.42453 20.9209 2.424 20.5045V18.6495C2.424 18.3281 2.29631 18.0198 2.06901 17.7925C1.84172 17.5652 1.53344 17.4375 1.212 17.4375C0.890558 17.4375 0.582281 17.5652 0.354987 17.7925C0.127692 18.0198 0 18.3281 0 18.6495V20.5045C-8.28123e-07 21.5645 0.42074 22.5812 1.1698 23.3312C1.91887 24.0812 2.935 24.5032 3.995 24.5045H19.763C20.8239 24.5045 21.8413 24.0831 22.5914 23.3329C23.3416 22.5828 23.763 21.5654 23.763 20.5045V18.6495C23.763 18.4899 23.7315 18.3319 23.6703 18.1845C23.609 18.0372 23.5193 17.9033 23.4062 17.7907C23.2932 17.6781 23.159 17.5889 23.0113 17.5283C22.8637 17.4677 22.7056 17.4369 22.546 17.4375Z"
                fill="#0A194D"
              />
              <path
                d="M11.8791 0.5C11.3649 0.5 10.8717 0.704287 10.508 1.06792C10.1444 1.43155 9.94013 1.92475 9.94013 2.439V11.505C9.94013 11.5713 9.91379 11.6349 9.8669 11.6818C9.82002 11.7287 9.75643 11.755 9.69013 11.755H7.03113C6.84512 11.7551 6.66306 11.8086 6.50663 11.9093C6.35021 12.0099 6.22603 12.1534 6.14888 12.3227C6.07174 12.492 6.04488 12.6798 6.07152 12.8639C6.09816 13.048 6.17717 13.2206 6.29913 13.361L11.1471 18.937C11.2381 19.0419 11.3505 19.126 11.4768 19.1836C11.6031 19.2412 11.7403 19.2711 11.8791 19.2711C12.0179 19.2711 12.1552 19.2412 12.2814 19.1836C12.4077 19.126 12.5202 19.0419 12.6111 18.937L17.4591 13.361C17.5811 13.2206 17.6601 13.048 17.6867 12.8639C17.7134 12.6798 17.6865 12.492 17.6094 12.3227C17.5322 12.1534 17.408 12.0099 17.2516 11.9093C17.0952 11.8086 16.9131 11.7551 16.7271 11.755H14.0681C14.0018 11.755 13.9382 11.7287 13.8914 11.6818C13.8445 11.6349 13.8181 11.5713 13.8181 11.505V2.439C13.8181 1.92475 13.6138 1.43155 13.2502 1.06792C12.8866 0.704287 12.3934 0.5 11.8791 0.5Z"
                fill="#0A194D"
              />
            </g>
            <defs>
              <clipPath id="clip0_218_7934">
                <rect width="24" height="25" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
        </span>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LooseObject } from '~/types/generic';
import PtFileIcon from '~/components/global/elements/PtFileIcon.vue';

defineProps({
  items: {
    type: Array as () => Array<LooseObject>,
    required: true,
  },
  onClick: {
    type: Function,
    default: () => {},
  },
});

const getExtFile = (path: string) => {
  const dividedPath = path.split('.');
  return dividedPath[dividedPath.length - 1].toLocaleLowerCase();
};
</script>
