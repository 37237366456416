<template>
  <div class="locations-map" :class="{ 'has-hovered': hovered }">
    <figure class="locations-map__image">
      <img src="/images/map-updated.svg" width="587" height="691" loading="lazy" alt="Map" />
    </figure>
    <locations-map-vector :selected="selected" @select="onRegionClick" @hover="onRegionHover" />
  </div>
</template>

<script lang="ts" setup>
import LocationsMapVector from '~/components/global/components/locations/LocationsMapVector.vue';

const emit = defineEmits(['region-click']);

defineProps({
  selected: {
    type: String,
    default: '',
  },
  loading: {
    type: String,
    default: 'lazy',
  },
});

const hovered = ref('');

const onRegionClick = (region: any) => {
  emit('region-click', region);
};
const onRegionHover = (region: any) => {
  hovered.value = region;
};
</script>
