<template>
  <div class="locations-list">
    <client-only>
      <pt-select
        v-if="regions.length"
        ref="select"
        v-model="region"
        label="title"
        input-id="locations-list-region-select"
        :options="regions"
        :filterable="false"
        :clearable="false"
        :searchable="false"
        :reduce="(i: any) => i.slug"
        class="locations-list__region"
      >
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <pt-icon name="arrow-select" :size="16" />
          </span>
        </template>
      </pt-select>
    </client-only>
    <div v-if="currentLocations.length" class="locations-list__items">
      <locations-list-item v-for="location in currentLocations" :key="`${region}-${location.id}`" :location="location" class="locations-list__item" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';
import LocationsListItem from '~/components/global/components/locations/LocationsListItem.vue';
import PtIcon from '~/components/global/elements/PtIcon.vue';
import PtSelect from '~/components/global/elements/PtSelect.vue';

const emit = defineEmits(['region-change']);

const props = defineProps({
  selected: {
    type: String,
    required: true,
  },
  locations: {
    type: Array,
    required: true,
  },
});

const region = ref(props.selected);

const regions = computed(() => {
  const list = props.locations.reduce((curr: any, location: any) => {
    if (!isArray(location.regions)) {
      return curr;
    }
    location.regions.forEach((region: any) => {
      if (!region.slug || curr[region.slug]) {
        return;
      }
      curr[region.slug] = region;
    });
    return curr;
  }, {});

  return sortBy(Object.values(list), (region) => region.order);
});

const currentLocations = computed<any[]>(() => {
  return props.locations.filter((l: any) => {
    return (l.regions || []).reduce((curr: any, rr: any) => {
      return curr || (rr && rr.slug === region.value);
    }, false);
  });
});

const setRegion = (r: any) => {
  region.value = r;
};

onMounted(() => {
  if (regions.value.length && !region.value) {
    setRegion(regions.value[0].slug);
  }
});

watch(region, (r) => {
  emit('region-change', r);
});

defineExpose({
  setRegion,
});
</script>
