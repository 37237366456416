<template>
  <div class="locations-list-item">
    <h3 class="locations-list-item__title lead-label is-strong">
      {{ location.title }}
    </h3>
    <address class="locations-list-item__address">
      <p>
        <template v-if="location.address">
          <a :href="location.google_maps_url" target="_blank" rel="nofollow noopener">
            <span>{{ location.address }}</span>
          </a>
        </template>
        <template v-if="!location.address && location.location">
          <span>{{ location.location }}</span>
        </template>
        <br />
        <span v-if="location.booking_link">
          <app-link v-if="digitalScreenMode" :to="`/digital-screen/book/?location=${location.slug}`" class="button is-primary margin-top-med">
            Book an appointment
          </app-link>
          <a v-else :href="location.booking_link" target="_blank" rel="nofollow noopener" class="button is-primary margin-top-med">
            Book an appointment
          </a>
        </span>
      </p>
    </address>
  </div>
</template>

<script lang="ts" setup>
import AppLink from '~/components/AppLink';
import { useDigitalScreenStore } from '~/stores/digital-screen';
import type { LooseObject } from '~/types/generic';

defineProps({
  location: {
    type: Object as () => LooseObject,
    required: true,
  },
});

const digitalScreenStore = useDigitalScreenStore();
const digitalScreenMode = computed(() => digitalScreenStore.digitalScreenMode);
</script>
