<template>
  <div class="featured-faqs-list">
    <div
      v-for="item in items"
      :key="item.objectID"
      :class="{
        'is-opened': currentId === item.objectID,
      }"
      class="featured-faqs-list__item"
    >
      <h4>
        <button class="featured-faqs-list__toggler" @click.prevent="() => toggle(item)">
          <span class="featured-faqs-list__toggler-icon">
            <pt-icon-faq-toggle />
          </span>
          <span class="featured-faqs-list__toggler-text title is-4">
            {{ item.title }}
          </span>
        </button>
      </h4>
      <transition name="featured-faqs-list__content" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div v-if="item.objectID === currentId" class="featured-faqs-list__content">
          <div>
            <bard :as-content="true" :content="item.description" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import PtIconFaqToggle from '~/components/global/icons/PtIconFaqToggle.vue';
import type { LooseObject } from '~/types/generic';
import Bard from '~/components/content/Bard.vue';

const props = defineProps({
  items: {
    type: Array as () => Array<LooseObject>,
    required: true,
  },
  onClick: {
    type: Function,
    default: () => {},
  },
});

const currentId = ref(null);

const toggle = (item) => {
  props.onClick('click', item, 'Hit Clicked!');
  currentId.value = currentId.value === item.objectID ? null : item.objectID;
};

const beforeEnter = (el) => {
  el.style.height = '0';
  el.style.opacity = '0';
};

const enter = (el) => {
  el.style.height = el.scrollHeight + 'px';
  el.style.opacity = '1';
};

const beforeLeave = (el) => {
  el.style.height = el.scrollHeight + 'px';
  el.style.opacity = '1';
};

const leave = (el) => {
  el.style.height = '0';
  el.style.opacity = '0';
};
</script>
