<template>
  <div
    class="accordion-item"
    :class="{
      'is-opened': isActive,
    }"
  >
    <component :is="titleTag" class="accordion-item__title" :class="titleClass">
      <template v-if="$slots.header">
        <slot :id="itemId" name="header" :is-active="isActive" :toggle="toggle" />
      </template>
      <template v-else>
        <button :id="itemId" :aria-expanded="isActive" class="accordion-item__toggler" @click.prevent="toggle()">
          <span class="accordion-item__toggler-title">
            {{ title }}
          </span>
          <span class="accordion-item__toggler-icon">
            <pt-icon-accordion-toggle />
          </span>
        </button>
      </template>
    </component>
    <div :aria-controls="itemId" :aria-hidden="!isActive" class="accordion-item__content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import kebabCase from 'lodash/kebabCase';
import PtIconAccordionToggle from '~/components/global/icons/PtIconAccordionToggle.vue';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  titleTag: {
    type: String,
    default: 'h3',
  },
  titleClass: {
    type: [String, Array, Object],
    default: null,
  },
  expanded: {
    type: Boolean,
    default: undefined,
  },
});

const emit = defineEmits(['toggle']);

const expanded = toRef(props, 'expanded');

const isActive = ref(false);

const itemId = computed(() => 'faq-item-' + kebabCase(props.title));

const toggle = () => {
  isActive.value = !isActive.value;

  nextTick(() => {
    const data = {
      item: this,
      isActive: isActive.value,
    };
    emit('toggle', data);
  });
};

watch(
  expanded,
  (val) => {
    if (typeof val !== 'undefined') {
      isActive.value = val;
    }
  },
  {
    immediate: true,
  }
);
</script>
