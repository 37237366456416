<template>
  <ul class="featured-pagination">
    <li v-if="showLabel" class="featured-pagination__item">
      {{ textResult }}
    </li>
    <li v-if="nbPages > 1" class="featured-pagination__item">
      <button class="featured-pagination__btn is-prev" :class="{ 'is-first': isFirstPage }" @click.prevent="refine(currentRefinement - 1)">
        <pt-icon-arrow />
      </button>
      <button class="featured-pagination__btn is-next" :class="{ 'is-last': isLastPage }" @click.prevent="refine(currentRefinement + 1)">
        <pt-icon-arrow />
      </button>
    </li>
    <li v-if="nbPages > 1" class="featured-pagination__item">{{ currentRefinement + 1 }} - {{ nbPages }}</li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import PtIconArrow from '@/components/global/icons/PtIconArrow.vue';

const props = defineProps({
  currentRefinement: {
    type: Number,
    required: true,
  },
  nbHits: {
    type: Number,
    required: true,
  },
  nbPages: {
    type: Number,
    required: true,
  },
  refine: {
    type: Function,
    required: true,
  },
  createUrl: {
    type: Function,
    required: true,
  },
  isFirstPage: {
    type: Boolean,
    default: true,
  },
  isLastPage: {
    type: Boolean,
    default: false,
  },
  showLabel: {
    type: Boolean,
    default: true,
  },
});

const textResult = computed(() => {
  let text = `${props.nbHits} result`;

  if (props.nbHits > 1) {
    text += 's';
  }

  return text;
});
</script>
