<template>
  <span>
    <svg width="21" height="3" viewBox="0 0 21 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.124 2.476C1.788 2.476 1.512 2.356 1.296 2.116C1.056 1.9 0.936 1.624 0.936 1.288C0.936 0.952 1.056 0.676 1.296 0.459999C1.512 0.22 1.788 0.0999998 2.124 0.0999998H19.224C19.56 0.0999998 19.836 0.22 20.052 0.459999C20.292 0.676 20.412 0.952 20.412 1.288C20.412 1.624 20.292 1.9 20.052 2.116C19.836 2.356 19.56 2.476 19.224 2.476H2.124Z"
        fill="currentColor"
      />
    </svg>
    <svg width="21" height="3" viewBox="0 0 21 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.124 2.476C1.788 2.476 1.512 2.356 1.296 2.116C1.056 1.9 0.936 1.624 0.936 1.288C0.936 0.952 1.056 0.676 1.296 0.459999C1.512 0.22 1.788 0.0999998 2.124 0.0999998H19.224C19.56 0.0999998 19.836 0.22 20.052 0.459999C20.292 0.676 20.412 0.952 20.412 1.288C20.412 1.624 20.292 1.9 20.052 2.116C19.836 2.356 19.56 2.476 19.224 2.476H2.124Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>
