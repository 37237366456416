import type { LooseObject, NullableOrUndefinable } from '~/types/generic';
import { getExtension } from '~/utils/strings';

export function getImageRatio(ratio, def = 0.667) {
  switch (ratio) {
    case '1_1':
      return 1;
    case '3_2':
      return 0.667;
    case '2_1':
      return 0.5;
    case '4_3':
      return 0.75;
    default:
      return def;
  }
}

export interface ObjectImage extends LooseObject {
  id: string;
  alt?: string;
  title?: string;
  url?: string;
}

export type NullableObjectImage = NullableOrUndefinable<ObjectImage>;

export function getRatio(image: NullableObjectImage, defaultRatio = 9 / 16) {
  return image && image.ratio ? 1 / image.ratio : defaultRatio;
}

export function getPictureBindings(image: NullableObjectImage, additional: LooseObject = {}) {
  if (!image) {
    return null;
  }

  const resizeable = !!(image.url && isResizeable(image.url));

  return {
    resizeable,
    contain: true,
    alt: getImageAlt(image),
    caption: image.caption,
    credit: image.credit,
    ratio: getRatio(image),
    src: image.id,
    focusCss: image.focus_css,
    ...additional,
  };
}

export function getImageAlt(image: NullableObjectImage, def = '') {
  return image ? image.alt || image.title || def : def;
}

export function isResizeable(url: string): boolean {
  const extension = getExtension(url);
  return !!(extension && ['jpg', 'jpeg', 'png', 'webp'].includes(extension));
}
